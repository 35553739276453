.pageTiles {
  @include tile--titleDesc();
  margin-top: 2rem;
  margin-bottom: 2rem;

  &.useSlider {
    display: block;
    .tile {
      max-width: 100%;
    }
  }
}

.pageTile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    // .pageTile_details:before {
    //   background: $primary-color;
    // }
    .pageTile_details {
      background: #008fc5;
    }

    .pageTile__title,
    .pageTile_desc {
      color: white;
    }

    .tile__bg {
      transform: scale(1.02);
    }
  }
}

.pageTile__title {
  font-family: $family-header;
  letter-spacing: 0.15rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin: 0.5rem 0;
  color: white;
}

.pageTile_desc {
  line-height: 1.3;
  font-size: 0.9rem;
  color: white;
}

.pageTile_details {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: 1rem;
  text-align: left;
  background-color: $primary-color;
}

.contentPanel--dark {
  .pageTile_details:before {
    background-image: linear-gradient(
      to bottom left,
      rgba(#fff, 0.3),
      rgba(#fff, 0)
    );
  }

  .pageTile__title,
  .pageTile_desc {
    color: white;
  }
}
