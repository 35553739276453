// overriding bootstrap variables in _boostrap-override.scss
@import url("https://use.typekit.net/nim1bjm.css");

$family-base: "proxima-nova", sans-serif;
$family-header: "jaf-bernino-sans-comp", sans-serif;

// font-size
$font-size-desktop: 21px;
$font-size-mobile: 16px;

$weight-thin: 100;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-extra-bold: 800;
$weight-normal: $weight-light;

// colors - sister colours from bootrap
$color-blue: #007bff;
$color-red: #c20f2f;
$color-orange: #fd7e14;
$color-green: #28a745;

// more colors
$color-gold: #faa222;
$color-dark-gray: #252525;
$color-dark-blue: #00467f;
$color-light-blue: #008fc5;

// site colors
$primary-color: $color-dark-blue;
$font-color: #404040;
$font-color-white: #fff;
$header-color: $primary-color;
$bg-color: #fff;
$link-color: $primary-color;
$link-hover-color: $color-light-blue;

// top-bar
$top-bar-border: (
  mobile: 5px,
  desktop: 12px,
);
$top-bar-height: (
  mobile: 44px,
  small-device: 82px,
  tablet: 82px,
  desktop: 82px,
);

// base transition
$transition-base: all 0.3s ease-in-out;
$transition-fast: all 0.2s ease-in-out;
$transition-slow: all 0.5s ease-in-out;
